import { NavLink } from 'react-router-dom';

const Navigation = () => {
    return (
        <nav className="Nav-container" activeClassName="active">
            <NavLink className="Nav-link" to="/airMaps">AirMaps</NavLink>
            <NavLink className="Nav-link-right" to="/contact">Contact</NavLink>
        </nav>
    );
};
export default Navigation;