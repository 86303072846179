
const Contact = () => {
    return (
        <div>
            <h4>Contact us at &nbsp;
                <a href='mailto:feedback@hakamari.com?Subject=Feedback'>
                    feedback@hakamari.com
                </a>
            </h4>
            <h4>Alternatively can toot at &nbsp;
                <a href='https://mastodon.social/@skandage'>
                @skandage@mastodon.social
                </a>
            </h4>
        </div>
    )
}

export default Contact