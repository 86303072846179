const AirMapsEula = () => {
    const containerStyle = {
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px',
        textAlign: 'left'
      };

      const dateStyle = {
        textAlign: 'right',
        color: 'gray'
      };

    return (
        <div style={containerStyle}>
            <p>
            <b>End-User License Agreement (EULA)</b>
        </p>

        <p>
            This End-User License Agreement ("EULA") is a legal agreement between you ("User") and Hakamari ("Licensor") for the use of AirMaps ("Software"). 
            By installing, copying, or using the Software, User agrees to be bound by the terms of this EULA.
        </p>

        <p>
            <b>License Grant:&nbsp;</b> 
            Licensor hereby grants User a non-exclusive, non-transferable license to use the Software for personal or commercial purposes.
        </p>

        <p>
            <b>Restrictions:&nbsp;</b> 
            User shall not: 
            (a) copy, modify, or distribute the Software; 
            (b) reverse engineer, decompile, or disassemble the Software; 
            (c) remove or alter any copyright, trademark, or other proprietary notices from the Software; or 
            (d) use the Software in any manner that violates applicable laws or regulations.
        </p>

        <p>
            <b>Disclaimer of Warranties:&nbsp;</b> 
            The Software is provided "as is" without warranty of any kind, either express or implied, 
            including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose. 
            Licensor does not warrant that the Software will meet User's requirements or that the operation of the Software will be uninterrupted or error-free.
        </p>

        <p>
            <b>Limitation of Liability:&nbsp;</b> 
            In no event shall Licensor be liable for any direct, indirect, incidental, special, 
            or consequential damages arising out of or in connection with the use or inability to use the Software, 
            even if Licensor has been advised of the possibility of such damages.
        </p>

        <p>
            <b>Termination:&nbsp;</b> 
            This EULA shall terminate automatically if User fails to comply with any of the terms and conditions herein. 
            Upon termination, User shall immediately cease all use of the Software and destroy all copies of the Software in User's possession or control.
        </p>

        <p>
            <b>Governing Law:&nbsp;</b> 
            This EULA shall be governed by and construed in accordance with the laws of the jurisdiction in which Licensor is located, 
            without giving effect to its conflict of laws provisions. User agrees to submit to the exclusive jurisdiction of the courts located in such jurisdiction.
        </p>

        <p>
            <b>Entire Agreement:&nbsp;</b> 
            This EULA constitutes the entire agreement between User and Licensor with respect to the Software and 
            supersedes all prior or contemporaneous oral or written communications and representations.
        </p>

        <p>
            By clicking "I agree" or installing, copying, or using the Software, User acknowledges that User has read this EULA, 
            understands it, and agrees to be bound by its terms and conditions.
        </p>
        <p style={dateStyle}>Last updated: June 24, 2023</p>
        </div>
    )
}

export default AirMapsEula