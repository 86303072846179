const AirMapsPP = () => {
    const containerStyle = {
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px',
        textAlign: 'left'
      };

      const dateStyle = {
        textAlign: 'right',
        color: 'gray'
      };
    return (
        <div style={containerStyle}>
            <p>
                <b>Privacy Policy:&nbsp;</b>
            </p>
            <p>
                This Privacy Policy describes how we handle and protect user data for AirMaps extension.
            </p>

            <p>
                <b>Information Collection and Use:&nbsp;</b>
                The App does not collect any personal information or data from users. 
                We do not gather, store, or share any identifiable information about you.
            </p>

            <p>
                <b>Data Security:&nbsp;</b>
                We take the security of user data seriously and implement appropriate measures to safeguard it. 
                However, please note that no method of transmission or storage over the internet is completely secure. 
                We cannot guarantee the absolute security of your information.
            </p>
            
            <p>
                <b>Third-Party Services:&nbsp;</b>
                The app uses Google maps API through your own API key. 
                Please refer to Google’s privacy policies as to how your data is handled. App will send data to the Google maps API.
            </p>

            <p>
                <b>Changes to This Privacy Policy:&nbsp;</b>
                We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "last updated" date. 
                We encourage you to review this Privacy Policy periodically for any updates.
            </p>
            <p>
                <b>Contact Us:&nbsp;</b>
                If you have any questions or concerns about this Privacy Policy or the App's data practices, 
                please contact us at <a href='mailto:airmaps-support@hakamari.com?Subject=Privacy'>
                    airmaps-support@hakamari.com
                </a>.
            </p>

            <p>
                By using the App, you agree to the terms outlined in this Privacy Policy. 
            </p>
            <p style={dateStyle}>Last updated: June 24, 2023</p>
        </div>
    )
}

export default AirMapsPP