import './App.css'
import Header from './Header.js'
import Home from './Home.js'
import AirMaps from './AirMaps.js'
import Contact from './Contact.js'
import AirMapsEula from './AirMapsEula'
import AirMapsPrivacyPolicy from './AirMapsPrivacyPolicy'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

function App() {
  document.title = 'Hakamari'
  return (
    <div className="App">
      <BrowserRouter>
        <Header></Header>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/airmaps" element={<AirMaps/>}/>
            <Route path="/contact" element={<Contact/>} />
            <Route path="/airmaps/eula" element={<AirMapsEula/>} />
            <Route path="/airmaps/privacy-policy" element={<AirMapsPrivacyPolicy/>} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
