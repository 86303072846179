
import React from 'react'
import demoVideo from './Media/Demo.mp4'
import googleMapsKeySettings from './Media/GoogleMapsKeySettings.png'
import euloSettings from './Media/EulaSettings.png'
import annotatedSettings from './Media/AnnotatedSettings.png'

const AirMaps = () => {
  const containerStyle = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'left'
  };

  const sectionStyle = {
    marginBottom: '40px',
  };

  const sectionHeadingStyle = {
    fontSize: '24px',
    marginBottom: '16px',
  };

  const contentStyle = {
    marginBottom: '20px',
  };
  
  const imageStyle1 = {
    width: '640px',
    height: '900px',
    marginBottom: '20px',
  };


  return (
    <div style={containerStyle}>
      <section style={sectionStyle}>
        <h2 style={sectionHeadingStyle}>Visualize your Airtable data in Google maps</h2>
        <div style={contentStyle}>
          <p>
            AirMaps is a powerful spatial data visualization extension to Airtable. 
            It integrates Google Maps in to Airtable user interface to bring your spatial data inside Airtable to life. 
            All you need is Google maps API key.
          </p>
          <p>
            AirMaps provide a way to juxtapose various sets of data from different tables and views through easy to configure unlimited number of layers.  
            It allows you to pick different marker icons, colors, and sizes to create perfect combinations suitable for your data. 
            Layers can be turned on and off at a click of a button making it easy to show and hide specific data.
          </p>
          <p>
            AirMaps also includes the ability to calculate geocodes from address and stores them in the table to avoid recalculations.
          </p>
          <p>
            Additional information about the marker can be displayed in the information window that is displayed when clicking on the marker, 
            It is configurable to show up to six pieces of information from the table. 
            In addition information window provides direct access to the complete record in the Airtable for easy access and displays a link to 
            directions in Google maps from your current location to the location of the selected marker.
            Clustering of the markers makes the visualization of the large datasets a breeze. Clusters honor the layer colors making layer data easy to tell apart.
          </p>
          <p>
            Various map styles like terrain, satellite, retro, dark, light etc. are available in AirMaps to let you pick most suitable map style for your data.
          </p>
          <p>
            <b>Demo video</b>
          </p>
          <div>
          <video width="100%" controls>
            <source src={demoVideo} type="video/mp4"/>
                Your browser does not support the video tag.
          </video>
          </div>
        </div>
      </section>

      <section style={sectionStyle}>
        <h2 style={sectionHeadingStyle}>Set up the extension in AirTable</h2>
        <div style={contentStyle}>
          <p>
            <b>End User license agreement: </b> End user license agreement screen is displayed when launchiung the extension for the first time
          </p>
          <img src={euloSettings} alt="End user license agreement" style={imageStyle1} />

          <p>
            <b>Google maps API Key: </b> Enter Google maps API key on this screen. You can obtain the key using the instructions provided
          </p>
          <img src={googleMapsKeySettings} alt="Google maps API key instructions" style={imageStyle1} />

        </div>
      </section>

      <section style={sectionStyle}>
        <h2 style={sectionHeadingStyle}>Settings</h2>
        <div style={contentStyle}>
          <img src={annotatedSettings} alt="Settings information" style={imageStyle1} />
          <p>
            <b>1:</b> Google maps API key field
          </p>
          <p>
            <b>2:</b> Button to switch to the map view
          </p>
          <p>
            <b>3:</b> Turn layer ON or OFF. Data from specific AirTable view is mapped to the layer.
          </p>
          <p>
            <b>4:</b> Name of the layer
          </p>
          <p>
            <b>5:</b> Delete the layer
          </p>
          <p>
            <b>6:</b> Pick the table
          </p>
          <p>
            <b>7:</b> Pick the view
          </p>
          <p>
            <b>8:</b> Pick the field which has or stores latitude values
          </p>
          <p>
            <b>9:</b> Pick the field which has or stores longitude values
          </p>
          <p>
            <b>10:</b> Turn longitude and latitude calculations ON or OFF
          </p>
          <p>
            <b>11:</b> Pick the field which has address values
          </p>
          <p>
            <b>12:</b> Turn display of field names on info window ON or OFF
          </p>
          <p>
            <b>13:</b> Pick the fields to be displayed on info window
          </p>
          <p>
            <b>14:</b> Turn clustering of markers ON or OFF
          </p>
          <p>
            <b>15:</b> Pick size of the markers
          </p>
          <p>
            <b>16:</b> Pick icon for the markers
          </p>
          <p>
            <b>17:</b> Pick color for the markers
          </p>
          <p>
            <b>18:</b> Add a new layer
          </p>

        </div>
      </section>
    </div>
  );
}

export default AirMaps