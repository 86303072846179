import logo from './Media/Hakamari.png'
import Navigation from './Navigation'

const Header = () => {
    return (
        <header className="App-header">
            <a href="/">
                <img src={logo} width={44} height={44} classname="App-logo" alt="logo" />
            </a>
            <Navigation></Navigation>
      </header>
        )
    }

export default Header